import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-pre-landing-with-id',
  templateUrl: './pre-landing-with-id.component.html',
  styleUrls: ['./pre-landing-with-id.component.scss'],
})
export class PreLandingWithIdComponent  implements OnInit {
 
  constructor(private router: Router) { }
 
  ngOnInit() {}
 
  async onFindAppointment(){
    let tenant = "f1f64c71-91e9-11eb-b050-4578cb1636b4";
    this.router.navigate(['/app/patient-appointment', tenant]);
  }
 
}