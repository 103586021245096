import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from './alt-views/login/page-not-found/page-not-found.component';
import { PreLandingWithIdComponent } from './main/self-scheduling/components/pre-landing-with-id/pre-landing-with-id.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'patient-appointment-link',
    pathMatch: 'full',
  },
  {
    path: 'patient-appointment-link',
    component: PreLandingWithIdComponent,
  },
  {
    path: 'app',
    loadChildren: () =>
      import('./main/main.module').then((m) => m.MainModule),
  },
  {
    path:'page-not-found',
    component: PageNotFoundComponent,
  },
  {
    path: '**',
    redirectTo: 'page-not-found',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
